<template>
    <CRow class="estandar-letra">

        <loading-overlay :active="$store.state.situacionOperativaActual.Loading" :is-full-page="true" loader="bars"/>
        <CCol sm="12">
            <CModalExtended
                color="dark"
                :show.sync="$store.state.situacionOperativaActual.modalInformation.show"
            >
                <template slot="header" >
                    <div class="d-flex align-content-center">
                        <div class="" style="margin-right:10px;">
                            <img v-if="!$store.state.situacionOperativaActual.modalInformation.iconShow" class="img-fluid rounded" height="20px" width="20px" src="/img/gruasOperativa.svg" alt="Card image cap">
                            <CIcon v-else style="color:#FFF" :name="$store.state.situacionOperativaActual.modalInformation.nameIcon"/>
                        </div>
                        <div class="flex-grow-1" style="color:#FFF">{{ $store.state.situacionOperativaActual.modalInformation.title }}</div>
                    </div>
                </template>
                <CRow>                                                      
                    <CCol sm="6" style="margin-bottom:10px;" v-for="(item,index) in $store.state.situacionOperativaActual.modalInformation.itemsInformation" :key="index">
                        <div class="d-flex align-content-center">
                            <div class="circuloOperativo" :style="'background:'+item.color"></div>
                            <div class="">{{item.label}}</div>
                        </div>
                    </CCol>
                </CRow>
                <div slot="footer">
                    <CButton
                        color="wipe"
                        @click="cerrarModal"
                        size="sm"
                        class="m-2"
                    >
                        <CIcon name="x"/>&nbsp;
                        {{$t('button.exit')}}
                    </CButton>
                </div>
            </CModalExtended>
            <CCard>
                <CCardHeader class="text-center bg-dark text-white" style="padding: 0.1rem;">
                    <div class="d-flex align-items-center">
                        <div>
                            <CButton
                                color="light"                            
                                @click="toggleCarrusel()"
                                size="sm"
                                shape="pill"
                                style="margin-right:10px"
                            >
                                <CIcon name="cil-media-pause" v-if="carrusel" />
                                <CIcon name="cil-media-play" v-else />
                            </CButton>
                        </div>
                        <div>
                            <CButton
                                color="light"                            
                                @click="resetData()"
                                size="sm"
                                shape="pill"
                                style="margin-right:10px"
                                v-c-tooltip="{placement:'top-end',content:$t('label.reset')}"
                            >
                                <CIcon name="cil-reload"/>
                            </CButton>
                        </div>
                        <div v-if="!isHidden">
                            <CButton
                                color="light"                            
                                @click="ocultarMenu()"
                                size="sm"
                                shape="pill"
                                v-c-tooltip="{placement:'top-end',content:$t('label.Maximize')}"
                            >
                                <CIcon name="cil-fullscreen"/>
                            </CButton>
                        </div>
                        <div v-else>
                            <CButton
                                color="light"                            
                                @click="ocultarMenu()"
                                size="sm"
                                shape="pill"
                                v-c-tooltip="{placement:'top-end',content:$t('label.Minimize')}"
                            >
                                <CIcon name="cil-fullscreen-exit"/>
                            </CButton>
                        </div>
                        <div class="flex-grow-1" style="font-weight: bold;">
                            <b style="font-weight: bold;">{{$t('label.OperationSituationCurrent')}}</b>
                        </div>
                        <div>
                            <label style="margin-bottom:0px;font-size:12px;margin-right:10px;">{{LastUpdated}}</label>
                        </div>
                    </div>
                </CCardHeader>
                <CCardBody class="pt-1">
                    <CTabs variant="tabs" :active-tab="$store.state.situacionOperativaActual.tabIndex" @update:activeTab="handleTab">
                        <CTab :title="$t('label.vesselPlanning')">
                            <PlanningList :info="vesselBoard"/>
                        </CTab>
                        <CTab v-for="(item, index) in myDataVessel" :key="index" :title="item.VesselName+','+item.VoyageArrival">
                            <div class="especial_menu position-relative" v-if="tabIndex === (index+1)">
                                <CTabs variant="tabs" :active-tab="$store.state.situacionOperativaActual.tabsOperationes" @update:activeTab="handleTabOperations">
                                    <CTab :title="$t('label.summary_of_operations')">
                                        <CCard bodyWrapper style="width: 100%;">
                                            <indexSummaryChatarra 
                                                :Vessel="item"
                                                :SummaryChatarraBackup="SummaryChatarraBackup"
                                                @backup="ServiceBackup"
                                                v-if="FgBulk"
                                            />
                                            <OperationSummary 
                                                :Vessel="item"
                                                :DischargeLoading="DischargeLoading"
                                                v-else-if="FgContainerShip" 
                                            />
                                            <OperationsSummaryGeneralCargo
                                                :Vessel="item"
                                                :DischargeLoading="DischargeLoading"
                                                v-else-if="FgGeneralCargo"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.OperationSituationOptions.OperationalSituation')" v-if="FgContainerShip || FgGeneralCargo">
                                        <CCard bodyWrapper style="width: 100%;">
                                            <OperationalSituation
                                                :Vessel="item"
                                                :VesselInfo="VesselInfo"
                                                :OperSituationToday="OperSituationToday"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab title="BL'S" v-if="FgGeneralCargo">
                                        <CCard bodyWrapper style="width: 100%;">
                                            <Bls
                                                :Vessel="item"
                                                :VesselInfo="VesselInfo"
                                                :OperSituationToday="OperSituationToday"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.hold')+'S'" v-if="FgBulk" >
                                        <CCard bodyWrapper style="width: 100%;">
                                            <indexBodega 
                                                :Vessel="item"
                                                :SummaryChatarraBackup="SummaryChatarraBackup"
                                                @backup="ServiceBackup"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.OperationSituationOptions.InactivityTime')">
                                        <CCard bodyWrapper style="width: 100%;">
                                            <InactivityTime 
                                                :Vessel="item"
                                                :IdleTime="IdleTime"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.client')+' BL'" v-if="FgBulk">
                                        <CCard bodyWrapper style="width: 100%;">
                                            <indexClienteBl :Vessel="item"></indexClienteBl>
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.crane')+'S'">
                                        <CCard bodyWrapper style="width: 100%;">
                                            <HistoryVesselsServed 
                                                :Vessel="item" 
                                                :ServicedVesselHist="ServicedVesselHist"
                                                :VesselSituationJson="VesselSituationJson"
                                                :HoldSituationJson="HoldSituationJson"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.transport')+'S'" v-if="FgBulk" >
                                        <CCard bodyWrapper style="width: 100%;">
                                            <indexTransporte :Vessel="item"></indexTransporte>
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.activities')">
                                        <CCard bodyWrapper style="width: 100%;">
                                            <Productivity 
                                                :Vessel="item"
                                                :ProductivityJson="ProductivityJson"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.OperationSituationOptions.ProductivityTime')">
                                        <CCard bodyWrapper style="width: 100%;">
                                            <ProductivityForTime 
                                                :Vessel="item"
                                                :ProductivityByTurnJson="ProductivityByTurnJson"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.Lines')" v-if="FgContainerShip || FgGeneralCargo" >
                                        <CCard bodyWrapper style="width: 100%;">
                                            <Lines 
                                                :Vessel="item"
                                                :LinesJson="LinesJson"
                                            />
                                        </CCard>
                                    </CTab>
                                    <CTab :title="$t('label.resume')" v-if="FgBulk" >
                                        <CCard bodyWrapper style="width: 100%;">
                                            <Resumen :Vessel="item"/>
                                        </CCard>
                                    </CTab>
                                </CTabs>
                            </div>
                        </CTab>
                    </CTabs>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import { mapState } from 'vuex';
    import OperationalSituation from './tabs/OperationalSituation/OperationalSituation';
    import InactivityTime from './tabs/InactivityTime/InactivityTime';
    import HistoryVesselsServed from './tabs/HistoryVesselsServed/HistoryVesselsServed';
    import TransversalViewMixin from "@/_mixins/transversal-view";
    import Lines from './tabs/Lines/Lines';
    import Productivity from './tabs/Productivity/Productivity';
    import ProductivityForTime from './tabs/ProductivityForTime/ProductivityForTime';
    import OperationSummary from './tabs/OperationSummary/Summary';
    import indexSummaryChatarra from './tabs/OperationSummaryChatarra/indexSummary';
    import indexBodega from './tabs/Bodega/indexBodega';
    import indexTransporte from './tabs/Transporte/indexTransporte';
    import indexClienteBl from './tabs/ClienteBL/indexClienteBl';
    import PlanningList from './tabs/StowagePlanning/PlanningList';
    import Resumen from './tabs/Resumen/index';
    import moment from 'moment';
    import OperationsSummaryGeneralCargo from './tabs/OperationSummary/operations-summary-general-cargo';
    import Bls from './tabs/Bls/bls';
    
    //data
    function data() {
        return {
            interval: null,
            resetInterval: null,
            vesselBoard: [],
            myDataVessel: [],
            SummaryChatarraBackup: [],
            ProductivityByTurnJson: [],
            DischargeLoading: {},
            VesselInfo: {},
            OperSituationToday: {},
            IdleTime: {},
            ServicedVesselHist: {},
            ProductivityJson: {},
            LinesJson: {},
            VesselSituationJson: {},
            HoldSituationJson: {},
        }
    }
    //methods
    function getServices() {
        this.$store.state.situacionOperativaActual.Loading = true;
        let requests = [
            this.$http.ejecutar("GET", "VisitOperationalSituationGeneralVessel", { CompanyBranchId: this.CompanyBranchId }),
            this.$http.ejecutar("GET", "PlanningVisitDashboard", { CompanyBranchId: this.CompanyBranchId }),
        ]
        Promise.all(requests)
            .then((responses) => {
                this.myDataVessel = responses[0].data.data;
                this.vesselBoard = responses[1].data.data;
            })
            .catch((err) => {
                this.$notify({
                    group: "container",
                    title: "¡Error!",
                    text: err,
                    type: "error",
                });
            }).finally(()=>{
                this.$store.state.situacionOperativaActual.Loading = false;
            });
    }
    function getContainerShip() {
        this.$store.state.situacionOperativaActual.Loading = true;
        let requests = [
            this.$http.ejecutar('GET', 'VisitOperationalSituationContainerShip', {VisitId: this.myDataVessel[this.tabIndex-1].VisitId}),
            this.$http.ejecutar('GET', 'VesselTransversalView-by-vesselA', { VesselId: this.myDataVessel[this.tabIndex-1].VesselId}),
        ]
        Promise.all(requests)
            .then((responses) => {
                let List = responses[0].data.data;
                this.DischargeLoading = List[0]?.ContainerResultJson[0]?.DischargeLoadingJson[0] ?
                    List[0].ContainerResultJson[0].DischargeLoadingJson[0] : {};
                this.VesselInfo = List[0]?.ContainerResultJson[0]?.VesselInfoJson[0] ? List[0].ContainerResultJson[0].VesselInfoJson[0] : {};
                this.OperSituationToday = List[0]?.ContainerResultJson[0]?.OperSituationTodayJson[0] ?
                    List[0].ContainerResultJson[0].OperSituationTodayJson[0] : {};
                this.IdleTime = List[0]?.ContainerResultJson[0]?.IdleTimeJson[0] ? List[0].ContainerResultJson[0].IdleTimeJson[0] : {};
                this.ServicedVesselHist = List[0]?.ContainerResultJson[0]?.ServicedVesselHistJson[0] ?
                    List[0].ContainerResultJson[0].ServicedVesselHistJson[0] : {};
                this.ProductivityJson = List[0]?.ContainerResultJson[0]?.ProductivityJson[0] ?
                    List[0].ContainerResultJson[0].ProductivityJson[0] : {};
                this.ProductivityByTurnJson = List[0]?.ContainerResultJson[0]?.ProductivityByTurnJson ?
                    List[0].ContainerResultJson[0].ProductivityByTurnJson : [];
                this.LinesJson = List[0]?.ContainerResultJson[0]?.LinesJson[0] ?
                    List[0].ContainerResultJson[0].LinesJson[0] : {};
                this.baysJson = responses[1].data.data || [];
            })
            .catch((err) => {
                this.DischargeLoading = {};
                this.VesselInfo = {};
                this.OperSituationToday = {};
                this.IdleTime = {};
                this.ServicedVesselHist = {};
                this.ProductivityJson = {};
                this.LinesJson = {},
                this.ProductivityByTurnJson = [];
                this.baysJson = [];
                this.$notify({
                    group: "container",
                    title: "¡Error!",
                    text: err,
                    type: "error",
                });
            }).finally(()=>{
                this.$store.state.situacionOperativaActual.Loading = false;
            });
    }

    function getGeneralCargo() {
        this.$store.state.situacionOperativaActual.Loading = true;
        let requests = [
            this.$http.ejecutar('GET', 'VisitOperationalSituationGeneralCargo', {VisitId: this.myDataVessel[this.tabIndex-1].VisitId}),
            //this.$http.ejecutar('GET', 'VesselTransversalView-by-vesselA', { VesselId: this.myDataVessel[this.tabIndex-1].VesselId}),
        ]
        Promise.all(requests)
            .then((responses) => {
                let Vessel = responses[0]?.data?.data[0] ?? {};
                this.DischargeLoading = Vessel?.GeneralCargoResultJson[0]?.DischargeLoadingJson[0] ?
                    Vessel.GeneralCargoResultJson[0].DischargeLoadingJson[0] : {};
                this.VesselInfo = Vessel?.GeneralCargoResultJson[0]?.VesselInfoJson[0] ? 
                    {
                        ...Vessel.GeneralCargoResultJson[0].VesselInfoJson[0],
                        TpVesselId: Vessel.TpVesselId,
                        TpVesselMasterId: Vessel.TpVesselMasterId,
                    } : {};
                this.OperSituationToday = Vessel?.GeneralCargoResultJson[0]?.OperSituationTodayJson[0] ?
                    Vessel.GeneralCargoResultJson[0].OperSituationTodayJson[0] : {};
                this.IdleTime = Vessel?.GeneralCargoResultJson[0]?.IdleTimeJson[0] ? Vessel.GeneralCargoResultJson[0].IdleTimeJson[0] : {};
                this.ServicedVesselHist = Vessel?.GeneralCargoResultJson[0]?.ServicedVesselHistJson[0] ?
                    Vessel.GeneralCargoResultJson[0].ServicedVesselHistJson[0] : {};
                this.ProductivityJson = Vessel?.GeneralCargoResultJson[0]?.ProductivityJson[0] ?
                    Vessel.GeneralCargoResultJson[0].ProductivityJson[0] : {};
                this.ProductivityByTurnJson = Vessel?.GeneralCargoResultJson[0]?.ProductivityByTurnJson ?
                    Vessel.GeneralCargoResultJson[0].ProductivityByTurnJson : [];
                this.VesselSituationJson = Vessel?.GeneralCargoResultJson[0]?.VesselSituationJson[0] ?? {};
                this.HoldSituationJson = Vessel?.GeneralCargoResultJson[0]?.HoldSituationJson[0] ?? {};
                this.LinesJson = Vessel?.GeneralCargoResultJson[0]?.LinesJson[0] ?
                    Vessel.GeneralCargoResultJson[0].LinesJson[0] : {};
            })
            .catch((err) => {
                this.DischargeLoading = {};
                this.VesselInfo = {};
                this.OperSituationToday = {};
                this.IdleTime = {};
                this.ServicedVesselHist = {};
                this.ProductivityJson = {};
                this.LinesJson = {},
                this.ProductivityByTurnJson = [];
                this.baysJson = [];
                this.$notify({
                    group: "container",
                    title: "¡Error!",
                    text: err,
                    type: "error",
                });
            }).finally(()=>{
                this.$store.state.situacionOperativaActual.Loading = false;
            });
    }

    
    function handleTab(tab) {
        this.DischargeLoading = {};
        this.VesselInfo = {};
        this.OperSituationToday = {};
        this.IdleTime = {};
        this.ServicedVesselHist = {};
        this.ProductivityJson = {};
        this.LinesJson = {},
        this.ProductivityByTurnJson = [];
        this.baysJson = [];
        this.VesselSituationJson = {};
        this.HoldSituationJson = {};
        this.$store.state.situacionOperativaActual.CurrentVessel = '';
        this.$store.state.situacionOperativaActual.carruselTab = 1;
        this.$store.state.situacionOperativaActual.tabsOperationes = 0;
        this.$store.state.situacionOperativaActual.tabIndex = tab;
        this.$store.state.situacionOperativaActual.FgGeneralCargo = false;
        this.$store.state.situacionOperativaActual.FgContainerShip = false;
        this.$store.state.situacionOperativaActual.FgBulk = false;

        if(this.myDataVessel&&this.myDataVessel.length!== 0&&this.tabIndex!== 0){
            if(this.myDataVessel[(this.tabIndex - 1)] !== undefined){
                this.$store.state.situacionOperativaActual.FgGeneralCargo = this.myDataVessel[this.tabIndex-1].TpVesselId === process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
                this.$store.state.situacionOperativaActual.FgContainerShip = this.myDataVessel[this.tabIndex-1].TpVesselId === process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID;
                this.$store.state.situacionOperativaActual.FgBulk = this.myDataVessel[this.tabIndex-1].TpVesselId === process.env.VUE_APP_BULK_VESSEL_ID;
            }
        }
        if (tab!=0&&this.FgContainerShip) {
            this.getContainerShip();
        }
        if (tab!=0&&this.FgGeneralCargo) {
            this.getGeneralCargo();
        }
    }
    function handleTabOperations(tab){
        this.$store.state.situacionOperativaActual.carruselTab = 1;
        this.$store.state.situacionOperativaActual.tabsOperationes = tab;
    }
    function toggleCarrusel() {
        this.$store.state.situacionOperativaActual.carrusel = !this.$store.state.situacionOperativaActual.carrusel;
    }
    function ocultarMenu(){
        this.$store.state.planificacionestiba.isHidden = !this.$store.state.planificacionestiba.isHidden;
    }
    function cerrarModal(){
        this.$store.state.situacionOperativaActual.modalInformation = {
            title:'',
            show:false,
            iconShow: false,
            nameIcon: "",
            itemsInformation:[]
        }
    }
    function ServiceBackup(event) {
        this.SummaryChatarraBackup = event;
    }
    //computed
    function LastUpdated(){
        return this.$t('label.LastUpdate') + moment().format('DD/MM/YYYY HH:mm');
    }
    export default {
        name:"situacionOperativaActual",
        data,
        methods:{
            getContainerShip,
            getGeneralCargo,
            ServiceBackup,
            getServices,
            handleTab,
            ocultarMenu,
            toggleCarrusel,
            handleTabOperations,
            cerrarModal,
            resetData(){
                this.$nextTick(async () => {
                    this.$store.state.situacionOperativaActual.carruselTab = 1;
                    this.$store.state.situacionOperativaActual.tabIndex = 0;
                    this.$store.state.situacionOperativaActual.tabsOperationes = 0;
                    this.getServices();
                })
            },
            _setInterval: function() {
                this.interval = setInterval(() => {
                    if (this.carrusel) {
                        let max = this.FgContainerShip ? 6 : 8;
                        this.$store.state.situacionOperativaActual.carruselTab = 1;
                        if(this.$store.state.situacionOperativaActual.tabsOperationes < max){
                            this.$store.state.situacionOperativaActual.tabsOperationes = this.$store.state.situacionOperativaActual.tabsOperationes + 1;
                        }else{
                            this.$store.state.situacionOperativaActual.tabsOperationes = 0;
                        }   
                    }
                }, 240000);
            },
            /*_setReset: function() {
                this.resetInterval = setInterval(() => {
                    this.$store.dispatch('situacionOperativaActual/getOperationalSituationReportlist',{});
                    this.getBoardData();
                }, 600000);
            }*/
        },
        components:{
            OperationalSituation,
            InactivityTime,
            HistoryVesselsServed,
            Lines,
            Productivity,
            ProductivityForTime,
            OperationSummary,
            indexSummaryChatarra,
            PlanningList,
            indexBodega,
            indexTransporte,
            indexClienteBl,
            Resumen,
            OperationsSummaryGeneralCargo,
            Bls,
        },
        mixins: [TransversalViewMixin],
        beforeMount(){
            this.$nextTick(async () => {
                this.getServices();
            })
        },
        mounted(){
            this.$nextTick(async () => {
                this._setInterval();
                //this._setReset();
            });
        },
        beforeDestroy(){
            this.myDataVessel = [];
            this.$store.state.situacionOperativaActual.FgGeneralCargo = false;
            this.$store.state.situacionOperativaActual.FgContainerShip = false;
            this.$store.state.situacionOperativaActual.FgBulk = false;
            this.$store.state.situacionOperativaActual.tabIndex = 0;
            this.$store.state.situacionOperativaActual.tabsOperationes = 0;
            this.$store.state.situacionOperativaActual.CurrentVessel = '';
            this.$store.state.situacionOperativaActual.carrusel = true;
            this.$store.state.situacionOperativaActual.carruselTab = 1;
            this.$store.state.situacionOperativaActual.Loading = false;
            this.$store.state.situacionOperativaActual.modalInformation = {
                title:'',
                show:false,
                iconShow: false,
                nameIcon: "",
                itemsInformation:[]
            }
            this.$store.state.situacionOperativaActual.transversalView = {
                toDeck: [],
                underDeck: [],
            };
            clearInterval(this.interval);
            clearInterval(this.resetInterval);
        },
        computed:{
            LastUpdated,
            ...mapState({
                Loading:  state => state.situacionOperativaActual.Loading,
                carrusel: state => state.situacionOperativaActual.carrusel,
                isHidden: state => state.planificacionestiba.isHidden,
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
                tabIndex: state => state.situacionOperativaActual.tabIndex,
                FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
                FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
                FgBulk: state => state.situacionOperativaActual.FgBulk,
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
            }),
        },
        watch:{
            baysJson: {
                handler(val, oldVal) {
                    this.$store.state.situacionOperativaActual.transversalView.toDeck = [...this.formatedToDeck];
                    this.$store.state.situacionOperativaActual.transversalView.underDeck = [...this.formatedUnderDeck];
                },
                deep: true
            },
            carrusel(newValue,OldValue){
                if(newValue){
                    this.$nextTick(async () => {
                        this._setInterval();
                    });
                }else{
                    clearInterval(this.interval);
                }
            }
        }
    }
</script>

<style lang="scss" >
    @import 'css/style.scss';
    @font-face {
        font-family: "Verdana";
        font-weight: 400;
        font-style: normal;
        font-display: auto;
        unicode-range: U+000-5FF;
        src: local("Verdana"), url(../../../public/fonts/verdana/verdana.ttf) format("truetype");
    }
    .estandar-letra{
        font-size: 0.75rem !important;
        font-weight: 100 !important;
        font-family: "Verdana", Helvetica, Arial;
        /*@font-face {
            font-family: "verdana";
            src: local("verdana"),
            url(./css/verdana/verdana-bold-italic.ttf) format("truetype");
        }*/
    }
    
</style>